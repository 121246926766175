import DxDataGrid, { DxSummary } from '@virtus/components/DxDataGrid';
import { DxDataGridProps } from '@virtus/components/DxDataGrid/DxDataGrid';
import { columnManagementIcon } from '@virtus/components/DxDataGrid/icons/svgicons';
import { DxHeaderText } from '@virtus/components/DxDataGrid/model/DxDataGrid.model';
import { DataSourceType } from '@virtus/components/DxDataGrid/utils/mapSchema';
import { getSummaryGlide, GlideDataType } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
import { GridActionColumn, RowAction } from 'src/components/grids/grid-action-column/grid-action-column';
import { Template } from 'devextreme-react/core/template';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// import indicatorSrc from 'src/assets/glide.gif';
import { GlideDataContentProps } from 'src/components/forms/glide-data-content';
import { useGlideObjectManager } from 'src/components/glide-object-manager/hooks/use-glide-object-manager';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// import { useMarketmap } from 'src/hooks/use-marketmap';
// import {
//   MarketMapColumns,
//   // MarketmapLivePriceDataType,
//   // marketmapRealTimeConfig,
//   // MarketmapRealTimeInfo,
// } from 'src/models/marketmap/marketmap.model';
import { RootState } from 'src/reducers';
import { actions, actions as reduxActions } from 'src/reducers/actions';
import { ComponentGridLayout, Components, selectComponents, selectViewComponent } from 'src/reducers/components';
import { getSortedActionsCollection, IAction } from 'src/utils/action-resolver';
import { onColumnGrouped } from 'src/utils/common';
import {
  CONFIRMATION_DIALOG,
  CONFIRM_DIALOG,
  DEFAULT_TOOLBAR_ICONS_ORDER,
  mapGlideObjectBgColor,
  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // MarketmapFields,
} from 'src/utils/constants';
import { CellData, SelectionChangeData } from './dxgrid-client-view.model';
import LayoutsTemplateRender from './templates/Layouts/Layouts';
import SelectTemplateRender from 'src/components/Select/Select';
import { dispatchActions, store } from 'src/app/store';
import GridTemplates from 'src/components/grids/grids.template';
import cellRenderers from './cell-renders/dxgrid-client-view.custom-cell-render';
import {
  cellClickAction,
  OnGridCellClickPayloadArguments,
  OnClickCompliancePayloadArguments,
  cellClickInspector,
  OnClickInspectorPayloadArguments,
  creditDetailInpector,
  complianceClickInspector,
} from 'src/sagas/glide-view.saga';
import {
  componentsActions,
  componentsResetPayload,
  DataGridActions,
} from 'src/components/grids/dxgrid-client-view/datagrid.saga';
import {
  InitActionParams,
  initLayoutAction,
  saveLayoutStateLocally,
  setLayoutAction,
  SetLayoutActionParams,
} from 'src/sagas/layouts/layouts.saga';
import { ViewBlotter } from 'src/api/queries';
import { View } from 'src/api/queries/display-view';
import { IColumnProps } from 'devextreme-react/data-grid';
import { selectCurrentForm } from 'src/reducers/inspectorForm.reducer';
import { useConfirmationDialog } from '@virtus/components/withConfirmationDialogOnClick/withConfirmationDialogOnClick';
import { updateEntities } from 'redux-query';
import { initCommentaryModal } from 'src/sagas/commentary/Commentary.saga';
import { useDispatch } from 'react-redux';
import { auditClick, globalModalClick } from 'src/sagas/glide-object-handler.saga';
import { selectGidDataToUpdate } from 'src/api/views/client-view';
import { NotificationsAction } from 'src/reducers/notifications';

export interface DxContextItem {
  text: string;
  icon?: string;
  onItemClick: () => void;
}

export interface DxContextEvent {
  component: Object;
  element: any;
  model: Object;
  target: string;
  targetElement: any;
  columnIndex: number;
  column: Object;
  rowIndex: number;
  row: any;
  items: Array<DxContextItem>;
}

export type ToolBarButtonsType = {
  add: any[];
  addBefore: any[];
  addAfter: any[];
  remove: any[];
};

export const addCloseMenuOption = (contextEvent: DxContextEvent) => {
  if (contextEvent.target === 'header') {
    const closeItem = {
      text: 'Close',
      onItemClick: () => {
        const element = document.getElementsByClassName('dx-submenu')[0] as HTMLElement;
        if (element) {
          element.style.visibility = 'hidden';
        }
      },
    };

    contextEvent.items.push(closeItem);
  }
};

export interface DxGridClientViewProps extends Omit<DxDataGridProps, 'dataSourceType'> {
  headerText?: DxHeaderText;
  actionsCollection?: any;
  dataGridRef: any;
  onContextMenuOpen?: (e: any) => void;
  onContextItemClick?: () => void;
  onSettingsClick: (_dataGridRef?: any) => void;
  enableRowDragging?: boolean;
  enableLayouts?: boolean;
  onCellClick?: (cellData: CellData) => void;
  rowActions?: RowAction[];
  cellsWithDisabledClick?: string[];
  objectEditActions?: GlideDataContentProps['objectEditActions'];
  objectEditInspectors?: GlideDataContentProps['objectEditInspectors'];
  scrollingProps?: any;
  gridToolbarButtons?: ToolBarButtonsType;
  clientViewConfiguration?: ClientViewConfigurationData;
  proposedOrdersVisible?: boolean;
  exportFileName?: string;
  onRowPrepared?: (cellData: CellData) => void;
  onRowClick?: (cellData: CellData) => void;
  // setInstanceUri: any;
  onAudit?: any;
}

interface ReduxProps {
  components: Components;
  clientViewData: View;
  gridLayout: ComponentGridLayout;
  currentInspectorForm: any;
  GridDataToUpdate: { key: string; data: object };
}

interface ReduxDispatch {
  resolveAction: (action: any, targetUri?: string) => void;
  handleRowSelection: () => void;
  initLayouts: ({ cvc, layouts }: InitActionParams) => void;
  setLayout: (payloadArguments: SetLayoutActionParams) => void;
  dispatchCellClick: (action: OnGridCellClickPayloadArguments) => void;
  dispatchComplianceClick: (action: OnClickCompliancePayloadArguments) => void;
  dispatchCellClickInspector: (action: OnClickInspectorPayloadArguments) => void;
  dispatchOpenCreditDetail: (payloadArguments: any) => void;
  dispatchGlobalModal: (payloadArguments: any) => void;
  resetDataGrid: () => void;
  resetColumnManager: ({ previousUri }: componentsResetPayload) => void;
  resetInspector: ({ previousUri }: componentsResetPayload) => void;
  onAuditClick: () => void;
}

const getToolbarButtons = ({
  add,
  addBefore,
  addAfter,
  openColumnManagerClickHandler,
  onSearchClick,
  gridToolbarButtons,
  showSearchText,
}: {
  openColumnManagerClickHandler: ((e: any) => void) | null;
  add: any;
  addBefore: any[];
  addAfter: any[];
  onSearchClick: ((add: any) => void) | null;
  gridToolbarButtons: any;
  showSearchText?: boolean;
}) => {
  if (!showSearchText) {
    add.unshift({
      name: 'searchButton',
      widget: 'dxButton',
      cssClass: 'vertical-separator-line',
      options: {
        icon: 'search',
        onClick: () => {
          if (onSearchClick) {
            onSearchClick(add);
          }
        },
        hint: 'Search',
        elementAttr: { 'data-testid': 'search-btn-action-panel' },
      },
      location: 'after',
    });
  }

  if (openColumnManagerClickHandler) {
    addAfter.unshift({
      name: 'columnManagementButton',
      widget: 'dxButton',
      options: {
        icon: columnManagementIcon,
        onClick: openColumnManagerClickHandler,
        hint: 'Column Management',
        elementAttr: { 'data-testid': 'columnManager-btn' },
      },
      location: 'after',
    });
  }

  if (gridToolbarButtons) {
    add = [...add, ...gridToolbarButtons.add];
    addAfter = [...addAfter, ...gridToolbarButtons.addAfter];
    addBefore = [...addBefore, ...gridToolbarButtons.addBefore];
  }

  return {
    remove: ['columnChooserButton'],
    add,
    addAfter,
    addBefore,
  };
};

// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// /***********Marketmap changes ******** */

// const handleMarketmapResponse = (
//   marketmapResData: { [key: number]: MarketmapLivePriceDataType },
//   realTimeInfo: MarketmapRealTimeInfo,
//   realTimeDataStore: ArrayStore | undefined,
// ) => {
//   for (const key in marketmapResData) {
//     const keyObj = marketmapResData[key];
//     if (keyObj && keyObj.Symbol && !keyObj.Error) {
//       const formattedRes = {
//         id: key,
//         'Last Price': keyObj[MarketmapFields.LAST_PRICE],
//         'Net Change': keyObj[MarketmapFields.NET_CHANGE],
//         'Perc Net Change': keyObj[MarketmapFields.PERC_NET_CHANGE],
//       };
//       /**
//        * At present we need to update only last price
//        * @todo in @future this can be updated later to handle multiple columns update
//        */
//       if (formattedRes['Last Price'] && formattedRes['Net Change']) {
//         formattedRes['Last Price'] = formatVal(formattedRes['Last Price']);
//         formattedRes['Net Change'] = formatVal(formattedRes['Net Change']);
//         formattedRes['Perc Net Change'] = formatVal(formattedRes['Perc Net Change']);
//         updateDataStore(realTimeDataStore, formattedRes, realTimeInfo.columnToUpdate);
//       }
//     }
//   }
// };

// /**
//  * Currently we are updating only last price
//  * @todo in @future handle multiple columns update based on business req
//  */
// const updateDataStore = (realTimeDataStore: ArrayStore | undefined, formattedRes: any, columnToUpdate: string) => {
//   realTimeDataStore?.push([
//     {
//       type: 'update',
//       key: +formattedRes.id - 1,
//       data: {
//         [columnToUpdate]: formattedRes['Last Price'] + ' ' + formattedRes['Net Change'],
//       },
//     },
//   ]);
// };

// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// eslint-disable-next-line no-confusing-arrow
// const formatVal = (val: any) =>
//   val?.toString().indexOf('[') >= 0 ? Number(val.substring(1, val.length - 1)) : Number(val);

export const handleOnRowPrepared = (e: any) => {
  if (e?.rowType === 'header') {
    e.rowElement.style.borderLeft = '5px solid var(--background-gride-header)';
  }
  if (e?.rowType === 'data') {
    if (e.data['Current Result'] === 'Passed') {
      e.rowElement.style.borderLeft = '5px solid var(--background-snackbar-header-success)';
    }
    if (e.data['Current Result'] === 'Failed') {
      e.rowElement.style.borderLeft = '5px solid var(--foreground-error)';
    }
    if (e.data['Current Result'] === 'Error') {
      e.rowElement.style.borderLeft = '5px solid var(--foreground-error-icon)';
    }
  }
};

export const DxGridClientView = ({
  onSelectionChanged,
  storageKey,
  headerText = {},
  dataGridRef,
  dataSource,
  // onCellClick,
  resolveAction,
  actionsCollection,
  onRefresh,
  customCellRenderKeyProps,
  enableLayouts = true,
  enableMultipleSelection = false,
  enableRowDragging = false,
  resetDataGrid,
  resetColumnManager,
  resetInspector,
  cellsWithDisabledClick = [],
  components,
  objectEditInspectors,
  objectEditActions,
  // loadPanelEnabled,
  onSettingsClick,
  scrollingProps,
  gridToolbarButtons,
  dispatchCellClick,
  onAuditClick,
  dispatchComplianceClick,
  dispatchCellClickInspector,
  clientViewConfiguration,
  showPaging,
  glideSummaryOptions,
  handleRowSelection,
  gridLayout,
  currentInspectorForm,
  dispatchOpenCreditDetail,
  dispatchGlobalModal,
  // setInstanceUri,
  GridDataToUpdate,
}: DxGridClientViewProps & ReduxProps & ReduxDispatch) => {
  if (!clientViewConfiguration || !dataSource) return null;
  const dispatch = useDispatch();
  const [showSearchText, setShowSearchText] = useState(false);
  if (!clientViewConfiguration.uri && !dataGridRef?.current?.props?.id) return null;

  const [rowActions, setRowActions] = useState<RowAction[]>([]);
  const [key, setKey] = useState<number>(Date.now());
  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  /**
   * ************** Marketmap related functionality changes @start ******************
   */
  // const {
  //   isLoggedInToMarketmap,
  //   marketMapToggleStatus,
  //   reqCountId,
  //   showMMAuthenticationForm,
  // } = components.livePriceToggleState;

  // const { livePrices, monitorLivePrices, stopMonitorLivePrices } = useMarketmap();
  const [realTimeDataSource, setRealTimeDataSource] = useState<DataSource | null>(null);
  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // const [showLoadPanelEnabled, setShowLoadPanelEnabled] = useState(loadPanelEnabled);
  // const [showIndicatorSrc, setShowIndicatorSrc] = useState(indicatorSrc);
  const realTimeDataStore = useRef<ArrayStore | undefined>(undefined);
  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // const marketMapToggleFlag = getLivePriceToggleStatus(
  //   marketMapToggleStatus,
  //   clientViewConfiguration.uri.lastSplitValue() as string,
  //   isLoggedInToMarketmap,
  // );
  // const livePriceEnabled = clientViewConfiguration?.toolbar_buttons?.some(v => v.includes('live_price'));

  const { toolbarButtonsFromConfig, toolbarTemplates } = GridTemplates({
    clientViewConfiguration,
  });

  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // const realTimeInfo: MarketmapRealTimeInfo | null = livePriceEnabled ? marketmapRealTimeConfig : null;
  const isInspectorFormDirty = useRef(undefined);

  useEffect(() => {
    if (realTimeDataStore.current && GridDataToUpdate && Object.keys(GridDataToUpdate).length > 0) {
      realTimeDataStore?.current?.push([
        {
          type: 'update',
          key: GridDataToUpdate.key,
          data: GridDataToUpdate.data,
        },
      ]);
    }
  }, [GridDataToUpdate]);

  useEffect(() => {
    isInspectorFormDirty.current = currentInspectorForm?.isFormDirty;
  }, [currentInspectorForm?.isFormDirty]);

  useEffect(() => {
    if (clientViewConfiguration?.enable_static_row_action && clientViewConfiguration?.static_menu_actions) {
      setRowActions(
        clientViewConfiguration.static_menu_actions?.map(action => ({
          text: action.data.display_name,
          onClick: (uri: string) => {
            const payloadAction = { actionUri: uri, action: action };
            if (action?.uri == 'instance/actions/create_new_close_position_order') {
              displayConfirmDialog(payloadAction);
            } else {
              dispatchMenuActions(payloadAction);
            }
          },
          bgColor: mapGlideObjectBgColor(action.data?.editor_button_style),
        })),
      );
    }
  }, [clientViewConfiguration?.enable_static_row_action]);

  /**
   * Needed to reset columngrouped and global order state as it is shared with multiple components
   */

  // TODO: Refactor so that those states are reset in the correct place (close icon on global order)
  //
  /*  useEffect(() => {
    return () => {
      batch(() => {
        dispatchActions.components.update('global', {
          orders: { disabled: true, rowData: {} },
        });
        dispatchActions.components.updateView('columnGrouped', clientViewConfiguration.uri, {
          isGrouped: false,
          isExpanded: false,
        });
      });
    };
  }, []);*/

  /**
   * Needed to hide loadpanel/indicator when live-streaming is on
   * As it looks awkward if for every price update, loader will appear
   */
  useEffect(() => {
    // Store state and option in window rather than redux as Javascript can't store JS references
    // This allows saga to interact with the grids as expected
    // @ts-ignore
    window['gridInstances'][clientViewConfiguration.uri] = dataGridRef;

    // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
    // if (realTimeInfo) {
    //   setShowLoadPanelEnabled(false);
    //   setShowIndicatorSrc(false);
    // }

    return () => {
      resetInspector({ previousUri: clientViewConfiguration.uri });
      resetColumnManager({ previousUri: clientViewConfiguration.uri });
      resetDataGrid();
    };
  }, []);

  /**
   * Initialize/Re-create realTimeDataStore and realTimeDataSource
   * once dataSource is defined or gets updated
   */
  useEffect(() => {
    if (dataSource && dataSource.data.length > 0) {
      if (realTimeDataStore.current) {
        /**
         * To avoid live-streaming of data based on cached dataSource
         * As per current tab navigation we are making ajax request on every tab switch
         * So our realtimeData should be configured based on latest dataSource fetched from API call.
         */
        // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
        // stopMonitorLivePrices();
        realTimeDataStore.current = undefined;
      }
      realTimeDataStore.current = new ArrayStore({
        key: '_uri',
        data: [...dataSource.data],
      });
      const realTimeDataSource = new DataSource({
        store: realTimeDataStore.current,
        reshapeOnPush: true,
      });
      setRealTimeDataSource(realTimeDataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  This is to re-render DxDataGrid and is only needed to override the ErrorBoundary render
  Use case:
  1/ DX crash on known filter issue when navigating back to a cached view
  2/ ErrorBoundary catches the error in DataGrid (Something wrong happened). We don't want this render to remain
  3/ dxgrid-client-view updates the key so another render happens.
*/

  useEffect(() => {
    setKey(Date.now());

    return () => {
      /**
       * Fix the memory leak issue when navigating between different tabs.
       * The tabs where reloading is disabled (like Bond universe, Loan Universe etc)
       */
      setRealTimeDataSource(null);
      realTimeDataStore.current = undefined;
    };
  }, [clientViewConfiguration.uri]);

  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // /**
  //  * Register tickers with marketmap to monitor last price and other values
  //  */
  // useEffect(() => {
  //   if (realTimeInfo && isLoggedInToMarketmap && realTimeDataSource && marketMapToggleFlag) {
  //     const tickerList = dataSource.data.map(item => item[realTimeInfo.columnToUse]);
  //     monitorLivePrices(tickerList, realTimeInfo.marketmapFields);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [realTimeInfo, realTimeDataSource, isLoggedInToMarketmap, marketMapToggleFlag]);

  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // /**
  //  * When field value changes update the grid by updating @var realTimeDataStore
  //  */
  // useEffect(() => {
  //   if (realTimeInfo && livePrices) {
  //     handleMarketmapResponse(livePrices, realTimeInfo, realTimeDataStore.current);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [livePrices]); // dont forget to add livePrices

  useEffect(() => {
    return () => {
      dispatchActions.inspectorForm.resetInspectorForm(clientViewConfiguration.uri);
      store.dispatch(
        updateEntities({
          views: (prev: any, _: any) => ({
            ...prev,
            [clientViewConfiguration.uri]: {
              ...prev?.[clientViewConfiguration.uri],
              viewInspector: {},
            },
          }),
        }),
      );
    };
  }, [clientViewConfiguration.uri, gridLayout?.selectedLayout]);

  const closeInspectorDialog = (e: any) => {
    if (isInspectorFormDirty.current) {
      dispatchActions.inspectorForm.resetInspectorForm(clientViewConfiguration.uri);
    }
    if (e?.column?.fixed) return;
    if (cellsWithDisabledClick.includes(e?.cellElement?.firstChild?.id)) return;
    if (
      e?.column?.dataType === GlideDataType.ObjectCollection &&
      !['Blog', 'Covenants', 'KPIs'].includes(e.column.dataField) &&
      e.event.target.tagName.toLowerCase() === 'span'
    ) {
      return;
    }
    if (!e?.data || e?.rowType === 'group') return;
    const isNotAnActionButton = [...e.cellElement.classList][0] !== 'dx-command-edit';
    const isAValidCell = e.rowType === 'data';
    if (isAValidCell && isNotAnActionButton) {
      dataGridRef.current.instance.selectRowsByIndexes([e.row?.rowIndex]);
    } else {
      return;
    }
    if (e.column.dataField === 'Current Result') {
      dispatchCellClick({
        instanceUri: e.data._uri,
        fieldName: e.data['Display Name'],
        _uri: e.data._uri,
      });
    } else if (e.column.dataField === 'Credit Detail' && e.value) {
      //Incase credit detail hyperlink we
      dispatchOpenCreditDetail({
        instanceUri: e.data[e.column.caption].uri,
      });
      dispatchActions.components.update('global', { instanceUri: e.data._uri });
    } else if (['Blog', 'Covenants', 'KPIs'].includes(e.column.dataField)) {
      const fieldName = e.column.caption;
      const commentaryUris = e.value;
      const creditUri = e.data['Credit Detail'];
      const instanceUri = e.data._uri;
      dispatch(initCommentaryModal({ fieldName, commentaryUris, creditUri, instanceUri, showModal: true }));
    } else if (e.data[e.column.caption]?.display_name && e.data[e.column.caption]?.uri) {
      // setInstanceUri(e.data[e.column.caption].uri);
      dispatchGlobalModal({
        instanceUri: e.data[e.column.caption].uri,
        cvcUri: clientViewConfiguration.uri,
      });
    } else if (clientViewConfiguration?.is_test_results) {
      dispatchComplianceClick({
        instanceUri: e.data._uri,
        fieldName: e.data['Test Name'],
      });
    } else {
      dispatchCellClickInspector({
        instanceUri: e.data._uri,
        fieldName: e.data['Display Name'],
        _uri: e.data._uri,
      });
      dispatchActions.components.update('actionForm', {
        target_uri: e.data._uri,
      });
    }
    dispatchActions.components.update('global', { selectedRowData: e.data });
  };

  const { DialogComponent: QuitInspector, onDispatcherClick: displayConfirmationDialog } = useConfirmationDialog({
    onClick: closeInspectorDialog,
    ...CONFIRMATION_DIALOG,
  });

  const closeActionFormDialog = (action: any) => {
    if (action?.isAllocationOptimization) {
      dispatchActions.execute({ glideAction: action });
    } else {
      dispatchMenuActions(action);
    }
  };

  const dispatchMenuActions = (payloadAction: any) => {
    store.dispatch({
      type: reduxActions.action.DISPATCH_ACTION,
      payload: { action: payloadAction?.action, targetUri: payloadAction?.actionUri },
    });
  };

  const { DialogComponent: QuitActionForm, onDispatcherClick: displayConfirmDialog } = useConfirmationDialog({
    onClick: closeActionFormDialog,
    ...CONFIRM_DIALOG,
  });

  const { objectCollectionColumns, GlideObjectManagerOverlay } = useGlideObjectManager({
    schema: dataSource?.schema,
    objectEditActions,
    objectEditInspectors,
    gridActions: {
      refresh: onRefresh,
    },
    id: clientViewConfiguration.uri,
  });

  const handleOnSelectionChanged = (data: SelectionChangeData) => {
    if (onSelectionChanged) {
      onSelectionChanged(data);
    }
  };

  if (
    enableRowDragging &&
    !dataSource?.schema.find((column: { accessor: string; display_name: string }) => column.display_name === 'drag')
  ) {
    dataSource.data = dataSource.data.map((row: any) => {
      return { ...row, drag: row };
    });
    dataSource.schema.push({
      display_name: 'drag',
      caption: '',
      data_type: 'Object',
      fixed: true,
      width: 50,
    });
  } else if (
    !enableRowDragging &&
    dataSource?.schema.find((column: { accessor: string; display_name: string }) => column.display_name === 'drag')
  ) {
    dataSource.schema = dataSource.schema.filter(
      (column: { accessor: string; display_name: string }) => column.display_name !== 'drag',
    );
    dataSource.data = dataSource.data.map((data: { [key: string]: string | number }) => {
      delete data.drag;
      return data;
    });
  }

  dataSource.schema = dataSource.schema
    ? dataSource?.schema.map((column: any) => {
        if (column.display_name === 'Order Date') {
          column.sortOrder = 'desc';
          column.sortIndex = 0;
          column.allowSorting = false;
        }
        return column;
      })
    : [];

  const handleOnCellClick = (e: any) => {
    if (isInspectorFormDirty.current) {
      e.event.stopPropagation();
      displayConfirmationDialog(e);
    } else {
      closeInspectorDialog(e);
    }
  };

  /*
   * Open Column Manager Inspector passing the current DXGrid columns as props
   */
  const showColumnManager = () =>
    dispatchActions.components.toggleViewDisplay('columnManagerInspector', clientViewConfiguration.uri);

  /**
   * Method called when clicked on search icon on toolbar
   * Mutating original array @addBtn over here for the simplification
   */
  const onSearchClick = useCallback((addBtn: any[]) => {
    const index = addBtn.findIndex((btn: any) => btn.name === 'searchButton');
    addBtn.splice(index, 1);
    setShowSearchText(true);
  }, []);

  const actionCollectionOnClick = (action: any) => {
    if (action?.uri === 'instance/actions/create_new_close_position_order' && !components?.actionForm?.target_uri) {
      store.dispatch({
        type: NotificationsAction.ERROR_NOTIFICATION,
        payload: {
          errorMessage: 'Please select a row',
        },
      });
      return;
    }

    if (action?.uri == 'instance/actions/create_new_close_position_order') {
      action.isAllocationOptimization = true;
      displayConfirmDialog(action);
    } else {
      dispatchActions.execute({ glideAction: action });
    }
  };

  const actionsCollectionView: IAction[] = useMemo(() => {
    if (clientViewConfiguration) {
      return getSortedActionsCollection(clientViewConfiguration, actionCollectionOnClick);
    } else if (actionsCollection) {
      return actionsCollection;
    }
  }, [clientViewConfiguration, resolveAction, actionsCollection, components.global.orders.rowData?._uri]);

  const getToolbarButtonsHandler = useMemo(
    () =>
      getToolbarButtons({
        add: [],
        addAfter: [],
        openColumnManagerClickHandler: showColumnManager,
        addBefore: [
          ...(actionsCollectionView?.length
            ? [
                {
                  name: 'selectCartComponent',
                  location: 'after',
                  template: 'selectedRowAction',
                  disabled: false,
                },
              ]
            : []),
        ],
        onSearchClick: onSearchClick,
        gridToolbarButtons,
        showSearchText,
      }),
    [actionsCollectionView, onSearchClick, showColumnManager, gridToolbarButtons, showSearchText],
  );

  function getSummary() {
    const summaryKeyProps: DxSummary[] = [
      {
        column: 'Instrument Behaviour',
        name: 'Instrument Behaviour',
        summaryType: 'custom',
      },
      {
        column: 'Identifier',
        name: 'Identifier',
        summaryType: 'custom',
      },
      // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
      // {
      //   column: 'Market Map ID',
      //   name: 'Market Map ID',
      //   summaryType: 'custom',
      // },
      {
        column: 'Asset Name',
        name: 'Asset Name',
        summaryType: 'custom',
      },
      {
        column: 'Primary Exchange',
        name: 'Primary Exchange',
        summaryType: 'custom',
      },
      {
        column: 'Asset Type',
        name: 'Asset Type',
        summaryType: 'custom',
      },
      {
        column: 'CCY',
        name: 'CCY',
        summaryType: 'custom',
      },
      // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
      // {
      //   column: MarketMapColumns.MarketMapLastPrice,
      //   name: MarketMapColumns.MarketMapLastPrice,
      //   summaryType: 'custom',
      // },
    ];

    const calculateCustomSummary = (options: any) => {
      const instrumentColumnDetails = options.component
        .state()
        .columns?.find((el: any) => el.dataField === 'Instrument');

      if (
        instrumentColumnDetails?.groupIndex > -1 &&
        options.summaryProcess === 'calculate' &&
        instrumentColumnDetails?.groupIndex === options.groupIndex
      ) {
        // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
        // if (options.name === MarketMapColumns.MarketMapLastPrice && typeof options.value === 'string') {
        //   options.totalValue = options.value.split(' ')[0];
        // } else {
        options.totalValue = options?.value;
        // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
        // }
      }
    };

    if (clientViewConfiguration?.uri === 'instance/display_views/portfolio_positions')
      return getSummaryGlide(dataSource.schema, { summaryKeyProps }, calculateCustomSummary);

    return getSummaryGlide(dataSource?.schema, {}, calculateCustomSummary);
  }

  const showColumnGroupedIcon = useCallback(
    (e: any) => {
      const visibleColumns = e.component.getVisibleColumns().filter((vc: any) => vc.hasOwnProperty('groupIndex'));
      if (!visibleColumns || !visibleColumns.length) {
        components?.viewComponents[clientViewConfiguration.uri]?.columnGrouped?.isGrouped &&
          dispatchActions.components.updateView('columnGrouped', clientViewConfiguration.uri, {
            isGrouped: false,
            isExpanded: false,
          });
        return;
      }
      if (!components?.viewComponents[clientViewConfiguration.uri]?.columnGrouped?.isGrouped) {
        dispatchActions.components.updateView('columnGrouped', clientViewConfiguration.uri, { isGrouped: true });
      }
    },
    [components?.viewComponents[clientViewConfiguration.uri]?.columnGrouped?.isGrouped],
  );
  const onContentReady = useCallback(
    (e: any) => {
      showColumnGroupedIcon(e);
    },
    [components?.viewComponents[clientViewConfiguration.uri]?.columnGrouped?.isGrouped],
  );

  const onOptionChanged = useCallback(
    (e: any) => {
      if (e.name === 'hoveredElement') return;
      if (e.fullName.includes('groupIndex')) {
        onColumnGrouped(e);
        showColumnGroupedIcon(e);
      }
    },
    [
      components?.viewComponents[clientViewConfiguration.uri]?.columnGrouped?.isGrouped,
      components?.viewComponents[clientViewConfiguration.uri]?.gridLayout?.selectedLayout?.uri,
      clientViewConfiguration.uri,
    ],
  );

  const onGroupedCallback = (isExpanded: boolean) => {
    dispatchActions.components.updateView('columnGrouped', clientViewConfiguration.uri, {
      isExpanded: !isExpanded,
    });
  };
  const onRowCollapsed = (e: any) => {
    const visibleRows = e.component.getVisibleRows().filter((vr: any) => vr.isExpanded);
    if (
      (!visibleRows || !visibleRows.length) &&
      components?.viewComponents[clientViewConfiguration.uri]?.columnGrouped?.isExpanded
    ) {
      dispatchActions.components.updateView('columnGrouped', clientViewConfiguration.uri, { isExpanded: false });
    }
  };

  const handleOnCellPrepared = (e: any) => {
    if (e.rowType === 'data' && e.column?.dataType === 'number') {
      e.cellElement && e.cellElement.classList.add('numeric-cell');
    } else if (
      e.rowType == 'data' &&
      e?.column?.dataType == 'string' &&
      typeof e?.value === 'string' &&
      e?.value?.indexOf('%') > 0 &&
      !isNaN(e?.value?.replace('%', '')) &&
      !isNaN(parseFloat(e?.value?.replace('%', '')))
    ) {
      e.cellElement.style.textAlign = 'right';
    }
  };

  const handleOnRowClick = (_e: any) => {
    handleRowSelection();
  };

  /*
- Save state into LS using CVC uri

State storing is necessary to work around a known DX Datagrid issue:
https://supportcenter.devexpress.com/ticket/details/t974945/datagrid-the-e1047-error-occurs-when-loading-the-grid-state-with-an-extra-column-in-the

   */
  const customSave = useCallback(() => {
    // Only save state locally (not on API) using a different key
    const _gridState = dataGridRef.current.instance.state();
    if (_gridState.columns) {
      saveLayoutStateLocally({ clientViewConfiguration, gridState: _gridState });
    }
  }, []);

  /*
  - Restore last selected layout
  - Restore previous state cached from LS using the grid uri
  - Apply layout filter

  State storing is necessary to work around a known DX Datagrid issue:
  https://supportcenter.devexpress.com/ticket/details/t974945/datagrid-the-e1047-error-occurs-when-loading-the-grid-state-with-an-extra-column-in-the
  */
  const customLoad = useCallback(() => {
    // const gridState = dataGridRef.current.instance.state();
    const gridState = gridLayout?.selectedLayout?.data?.json_layout || dataGridRef.current.instance.state();
    // dataGridRef.current.instance.state(gridState);
    const filterValue = gridState.filterValue;

    if (filterValue) {
      try {
        dataGridRef.current.instance.option('filterValue', filterValue);
      } catch (e) {
        console.info('[dxgrid-client-view] reset filter value');
        dataGridRef.current.instance.option('filterValue', null);
        (gridState.columns || []).forEach(function (c: IColumnProps) {
          c.filterValue = null;
          c.filterValues = [];
        });
      }
    }

    return Promise.resolve(gridState);
  }, [gridLayout]);

  const handleonAudit = () => {
    onAuditClick();
  };
  const dataGridProps: DxDataGridProps = {
    dataSource,
    dataSourceType: DataSourceType.GLIDE,
    customCellRenderKeyProps: {
      ...cellRenderers.selectionCellRenders(),
      // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
      // ...cellRenderers.selectionCellRenders(dataSource),
      ...objectCollectionColumns,
      ...customCellRenderKeyProps,
      ...cellRenderers.complianceCellRender(),
      ...cellRenderers.currentResultCellRender,
      ...cellRenderers.complianceTestResultCellRender,
      ...cellRenderers.positionCellRender(),
      ...cellRenderers.displayOverlaysCellRender,
    },
    // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
    // loadPanelEnabled: showLoadPanelEnabled,
    storageKey: storageKey,
    enableMultipleSelection,
    onSelectionChanged: handleOnSelectionChanged,
    tableKeyId: '_uri',
    gridTitle: headerText.title,
    gridDescription: headerText.description,
    id: clientViewConfiguration.uri,
    // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
    // indicatorSrc: showIndicatorSrc,
    enableLayouts,
    glideSummaryOptions,
    onRefresh,
    disableSelection: false,
    disableStateStoring: false,
    pageSizeOptions: [20, 50, 100, 200, 500],
    pageSize: 100,
    renderAsync: false,
    dxDataGridProps: {
      onCellClick: handleOnCellClick,
      toolbarButtons: getToolbarButtonsHandler,
      onOptionChanged,
      onContentReady,
      onGroupedCallback,
      onRowCollapsed,
      onRowPrepared: handleOnRowPrepared,
      onCellPrepared: handleOnCellPrepared,
      customLoad,
      customSave,
      key,
    },
    realTimeDataSource,
    repaintChangesOnly: !!realTimeDataStore,
    highlightChanges: !!realTimeDataStore,
    scrollingProps: scrollingProps ? scrollingProps : {},
    isFilterButtonLeftAligned: true,
    showSearch: showSearchText,
    searchVisibleColumnsOnly: true,
    toolbarIconsOrder: DEFAULT_TOOLBAR_ICONS_ORDER, // Default Icon order on toolbar can always be overridden for any screen specific
    showPaging: showPaging,
    allowHeaderSearch: true,
    autoExpandAll: components?.viewComponents[clientViewConfiguration.uri]?.columnGrouped?.isExpanded,
    exportFileName: clientViewConfiguration.display_name || clientViewConfiguration.uri.lastSplitValue(),
    toolbarButtonsFromConfig,
    columnGrouped: components?.viewComponents[clientViewConfiguration.uri]?.columnGrouped,
    onRowClick: handleOnRowClick,
    onAudit: handleonAudit,
  };

  const handleSettingsClick = () => {
    if (onSettingsClick) {
      onSettingsClick(dataGridRef);
    }
  };

  const Grid = useMemo(
    () => (
      <>
        <DxDataGrid ref={dataGridRef} {...dataGridProps}>
          {/*
          THE COMPONENT WILL NOT RENDER WHEN PASSED AS JSX!
          It appears only DevExtreme components are rendered correctly as JSX to DataGrid.
          This seems to be an issue with DevExtreme DataGrid
          */}
          {rowActions && rowActions.length > 0 && GridActionColumn({ actions: rowActions })}
          {actionsCollectionView && (
            <Template
              name="selectedRowAction"
              render={() => <SelectTemplateRender actionsCollection={actionsCollectionView} />}
            />
          )}
          {enableLayouts && (
            <Template
              name="layouts"
              render={() => (
                <LayoutsTemplateRender
                  onSettingsClick={handleSettingsClick}
                  clientViewUri={clientViewConfiguration.uri}
                />
              )}
            />
          )}
          {getSummary()}
          {toolbarTemplates && toolbarTemplates.map((template: any) => template)}
        </DxDataGrid>
        {/* <GlideContextMenu onContextItemClick={onContextItemClick} /> */}
      </>
    ),
    //⚠️ Try not to include any dependency over here to avoid datagrid re-rendering issues.
    [
      showSearchText,
      actionsCollection,
      components.viewComponents[clientViewConfiguration.uri]?.columnGrouped?.isGrouped,
      components.viewComponents[clientViewConfiguration.uri]?.columnGrouped?.isExpanded,
      // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
      // marketMapToggleStatus,
      // showMMAuthenticationForm,
      // marketMapToggleFlag,
      components.viewComponents[clientViewConfiguration.uri]?.hypoScenario?.runStatus,
      components.viewComponents[clientViewConfiguration.uri]?.hypoScenario?.runStatusUri,
      rowActions,
      key,
      clientViewConfiguration.uri,
    ],
  );

  return (
    <>
      <QuitInspector />
      <QuitActionForm />
      {GlideObjectManagerOverlay}
      {Grid}
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): ReduxProps => ({
  components: selectComponents(state),
  clientViewData: ViewBlotter.selectClientViewData(state),
  gridLayout: selectViewComponent(state, 'gridLayout', ownProps?.clientViewConfiguration?.uri),
  currentInspectorForm: selectCurrentForm(state),
  GridDataToUpdate: selectGidDataToUpdate(state),
});

const mapDispatchToProps = (dispatch: any): ReduxDispatch => ({
  resolveAction: (action: any, targetUri?: string) =>
    dispatch({ type: actions.action.DISPATCH_ACTION, payload: { action, targetUri } }),
  handleRowSelection: () => dispatch({ type: 'DATAGRID_ROW_SELECTION', payload: null }),
  dispatchCellClick: (payloadArguments: OnGridCellClickPayloadArguments) => dispatch(cellClickAction(payloadArguments)),
  dispatchCellClickInspector: (payloadArguments: OnClickInspectorPayloadArguments) =>
    dispatch(cellClickInspector(payloadArguments)),
  dispatchComplianceClick: (payloadArguments: OnClickCompliancePayloadArguments) =>
    dispatch(complianceClickInspector(payloadArguments)),
  dispatchGlobalModal: (payloadArguments: { instanceUri: string }) => dispatch(globalModalClick(payloadArguments)),
  dispatchOpenCreditDetail: (payloadArguments: { instanceUri: string }) =>
    dispatch(creditDetailInpector(payloadArguments)),
  initLayouts: (payloadArguments: InitActionParams) => dispatch(initLayoutAction(payloadArguments)),
  setLayout: (payloadArguments: SetLayoutActionParams) => dispatch(setLayoutAction(payloadArguments)),
  resetDataGrid: () => dispatch({ type: 'DATAGRID_RESET' as DataGridActions, payload: null }),
  resetColumnManager: ({ previousUri }: componentsResetPayload) =>
    dispatch({ type: componentsActions.RESET_COLUMN_MANAGER, payload: { previousUri } }),
  resetInspector: ({ previousUri }: componentsResetPayload) =>
    dispatch({ type: componentsActions.RESET_INSPECTOR, payload: { previousUri } }),
  onAuditClick: () => dispatch(auditClick()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DxGridClientView);
